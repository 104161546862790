import React, { useMemo } from "react";
import CustomList from "../custom/CustomList";
import classnames from "classnames";

const ReservationsMMIP = ({ data }) => {
    console.log("ReservationsMMIP.jsx - rendering with data:", data);
    const columns = useMemo(
        () => [
            {
                Header: "Référence",
                accessor: "reference",
                icon: "role",
            },
            {
                Header: "Date d'arrivée",
                accessor: "date_arrivee",
                icon: "calendar",
                Cell: ({ cell: { value } }) => value || "Date non disponible",
            },
            {
                Header: "Date de départ",
                accessor: "date_depart",
                icon: "calendar",
                Cell: ({ cell: { value } }) => value || "Date non disponible",
            },
            {
                Header: "Date de la réservation",
                accessor: "date_resa",
                icon: "calendar",
                Cell: ({ cell: { value } }) => value || "Date non disponible",
            },
            {
                Header: "Montant de la réservation",
                accessor: "montant_resa",
                class: "border-item",
                attribut: "€",
                icon: "euro",
                Cell: ({ cell: { value } }) => <span>{value ? value.toFixed(2) : "0.00"}</span>,
            },
        ],
        []
    );

    const containerClasses = classnames("transactions-container input-orders-container", {
        sample: data?.sample,
    });

    const reservationsData = Array.isArray(data) ? data : [];

    return (
        <div className={containerClasses}>
            {reservationsData.length > 0 ? (
                <CustomList
                    entity={reservationsData}
                    columns={columns}
                    isFetchable={false}
                    isSelectable={false}
                    isPaginable={true}
                    pageSizeOptions={[5, 10]}
                    isSmall={true}
                />
            ) : (
                <div className="login-error">Aucune réservation disponible. Veuillez vérifier les dates.</div>
            )}
        </div>
    );
};

export default React.memo(ReservationsMMIP);
