import sampleBookings from "./sample/reservations.json";
import dayjs from "dayjs";

const regionNames = new Intl.DisplayNames(["fr"], { type: "region" });

const channel = (val) => {
    console.log("functionGlobal.jsx - channel called with val:", val);
    switch (val) {
        case "Metasearch_googlehpa":
            return "Google";
        case "Metasearch_trivago":
            return "Trivago";
        case "Metasearch_bing":
            return "Bing";
        case "Metasearch_tripadvisor":
            return "Tripadvisor";
        case "undefined":
            return "Undefined";
        default:
            return val || "Undefined";
    }
};

export function globalAds(ads) {
    console.log("functionGlobal.jsx - globalAds called with ads:", ads);
    let arr;
    if (ads !== null && ads.length !== 0 && ads.length !== undefined) {
        arr = {
            data: [
                {
                    label: "Montant des réservations",
                    attribut: "€",
                    data_0: ads[0].data.allConversionsValue,
                    data_1: ads[1].data.allConversionsValue,
                    data_2: ads[2].data.allConversionsValue,
                    data_3: ads[3].data.allConversionsValue,
                    tooltip: "Les montants des réservations sont la somme des réservations obtenues sur la période avec ads.",
                },
                {
                    label: "Réservations",
                    isNotRound: true,
                    data_0: ads[0].data.conversions,
                    data_1: ads[1].data.conversions,
                    data_2: ads[2].data.conversions,
                    data_3: ads[3].data.conversions,
                    tooltip: "Le coût correspond à la somme de vos dépenses au cours de cette période.",
                },
                {
                    label: "Equiv. commission",
                    attribut: "%",
                    decimals: 2,
                    data_0: ads[0].data.earnings_percent * 100,
                    data_1: ads[1].data.earnings_percent * 100,
                    data_2: ads[2].data.earnings_percent * 100,
                    data_3: ads[3].data.earnings_percent * 100,
                },
                {
                    label: "ROI",
                    decimals: 2,
                    data_0: ads[0].data.roi,
                    data_1: ads[1].data.roi,
                    data_2: ads[2].data.roi,
                    data_3: ads[3].data.roi,
                },
                {
                    label: "Coût",
                    attribut: "€",
                    data_0: ads[0].data.costMicros,
                    data_1: ads[1].data.costMicros,
                    data_2: ads[2].data.costMicros,
                    data_3: ads[3].data.costMicros,
                },
                {
                    label: "Panier moyen",
                    attribut: "€",
                    data_0: ads[0].data.conversionsValue / ads[0].data.conversions,
                    data_1: ads[1].data.conversionsValue / ads[1].data.conversions,
                    data_2: ads[2].data.conversionsValue / ads[2].data.conversions,
                    data_3: ads[3].data.conversionsValue / ads[3].data.conversions,
                },
                {
                    label: "Appels",
                    data_0: ads[0].data.phoneCalls,
                    data_1: ads[1].data.phoneCalls,
                    data_2: ads[2].data.phoneCalls,
                    data_3: ads[3].data.phoneCalls,
                    isNotRound: true,
                    tooltip:
                        "Les annonces Appel sont conçues pour encourager les utilisateurs à contacter votre entreprise par téléphone. Elles ne peuvent être diffusées que sur les appareils qui permettent de passer des appels. Lorsqu'un client potentiel clique sur votre annonce, l'annonce déclenche un appel vers votre établissement depuis son appareil.",
                },
                {
                    label: "Impressions",
                    data_0: ads[0].data.impressions,
                    data_1: ads[1].data.impressions,
                    data_2: ads[2].data.impressions,
                    data_3: ads[3].data.impressions,
                    isNotRound: true,
                    tooltip: "Les impressions vous permettent de déterminer combien de fois votre annonce a été vue.",
                },
                {
                    label: "Clics",
                    data_0: ads[0].data.clicks,
                    data_1: ads[1].data.clicks,
                    data_2: ads[2].data.clicks,
                    data_3: ads[3].data.clicks,
                    isNotRound: true,
                    tooltip: "Il s'agit du nombre de fois où les internautes ont cliqué sur votre annonce.",
                },
                {
                    label: "CTR",
                    attribut: "%",
                    data_0: `${ads[0].data.ctr * 100}`,
                    data_1: `${ads[1].data.ctr * 100}`,
                    data_2: `${ads[2].data.ctr * 100}`,
                    data_3: `${ads[3].data.ctr * 100}`,
                    tooltip:
                        "Le taux de clics (CTR) détermine la fréquence à laquelle les utilisateurs cliquent sur votre annonce lorsqu'ils la voient et vous permet d'évaluer son efficacité.",
                },
                {
                    label: "Cpc moy.",
                    attribut: "€",
                    data_0: ads[0].data.average_cpc,
                    data_1: ads[1].data.average_cpc,
                    data_2: ads[2].data.average_cpc,
                    data_3: ads[3].data.average_cpc,
                    tooltip:
                        "Le coût par clic (CPC) moyen correspond au montant que vous avez payé pour votre annonce, divisé par le nombre total de clics qu'elle a générés.",
                },
                {
                    label: "Taux de conv.",
                    attribut: "%",
                    data_0: `${ads[0].data.conversion_rate * 100}`,
                    data_1: `${ads[1].data.conversion_rate * 100}`,
                    data_2: `${ads[2].data.conversion_rate * 100}`,
                    data_3: `${ads[3].data.conversion_rate * 100}`,
                    tooltip:
                        "La statistique relative au taux de conversion ('Taux de conv.') correspond à la fréquence moyenne à laquelle une interaction avec une annonce génère une conversion. Il s'agit des données 'Conversions' divisées par le nombre d'interactions avec votre annonce.",
                },
            ],
        };
    }
    console.log("functionGlobal.jsx - globalAds result:", arr);
    return arr;
}

export function globalMeta(meta, metaCost) {
    console.log("functionGlobal.jsx - globalMeta called with meta:", meta, "metaCost:", metaCost);
    let arr;
    if (meta && metaCost !== null) {
        arr = {
            data: [
                {
                    label: "Montant des réservations",
                    attribut: "€",
                    data_0: meta?.[0]?.data?.all?.total_amount_booking || 0,
                    data_1: meta?.[1]?.data?.all?.total_amount_booking || 0,
                    data_2: meta?.[2]?.data?.all?.total_amount_booking || 0,
                    data_3: meta?.[3]?.data?.all?.total_amount_booking || 0,
                    tooltip: "Les montants des réservations sont la somme des réservations obtenues sur la période avec meta.",
                },
                {
                    label: "Réservations",
                    isNotRound: true,
                    data_0: meta?.[0]?.data?.all?.count_booking || 0,
                    data_1: meta?.[1]?.data?.all?.count_booking || 0,
                    data_2: meta?.[2]?.data?.all?.count_booking || 0,
                    data_3: meta?.[3]?.data?.all?.count_booking || 0,
                },
                {
                    label: "Equiv. commission",
                    attribut: "%",
                    decimals: 2,
                    data_0:
                        meta?.[0]?.data?.all !== null && metaCost.datesRange?.[0]?.cost !== null
                            ? (1 / (meta[0]?.data?.all.total_amount_booking / metaCost.datesRange?.[0]?.cost)) * 100
                            : 0,
                    data_1:
                        meta?.[1]?.data?.all !== null && metaCost.datesRange?.[1]?.cost !== null
                            ? (1 / (meta[1]?.data?.all.total_amount_booking / metaCost.datesRange?.[1]?.cost)) * 100
                            : 0,
                    data_2:
                        meta?.[2]?.data?.all !== null && metaCost.datesRange?.[2]?.cost !== null
                            ? (1 / (meta[2]?.data?.all.total_amount_booking / metaCost.datesRange?.[2]?.cost)) * 100
                            : 0,
                    data_3:
                        meta?.[3]?.data?.all !== null && metaCost.datesRange?.[3]?.cost !== null
                            ? (1 / (meta[3]?.data?.all.total_amount_booking / metaCost.datesRange?.[3]?.cost)) * 100
                            : 0,
                },
                {
                    label: "ROI",
                    decimals: 2,
                    data_0:
                        meta?.[0]?.data?.all !== null && metaCost.datesRange?.[0]?.cost !== null
                            ? meta[0].data.all.total_amount_booking / metaCost.datesRange?.[0]?.cost
                            : 0,
                    data_1:
                        meta?.[1]?.data?.all !== null && metaCost.datesRange?.[1]?.cost !== null
                            ? meta[1].data.all.total_amount_booking / metaCost.datesRange?.[1]?.cost
                            : 0,
                    data_2:
                        meta?.[2]?.data?.all !== null && metaCost.datesRange?.[2]?.cost !== null
                            ? meta[2].data.all.total_amount_booking / metaCost.datesRange?.[2]?.cost
                            : 0,
                    data_3:
                        meta?.[3]?.data?.all !== null && metaCost.datesRange?.[3]?.cost !== null
                            ? meta[3].data.all.total_amount_booking / metaCost.datesRange?.[3]?.cost
                            : 0,
                },
                {
                    label: "Coût",
                    attribut: "€",
                    data_0: metaCost.datesRange?.[0]?.cost || meta?.[0]?.data?.meta_extra?.cost || 0,
                    data_1: metaCost.datesRange?.[1]?.cost || meta?.[0]?.data?.meta_extra?.cost || 0,
                    data_2: metaCost.datesRange?.[2]?.cost || meta?.[0]?.data?.meta_extra?.cost || 0,
                    data_3: metaCost.datesRange?.[3]?.cost || meta?.[0]?.data?.meta_extra?.cost || 0,
                    tooltip: "Le coût correspond à la somme de vos dépenses au cours de cette période.",
                },
                {
                    label: "Panier moyen",
                    attribut: "€",
                    data_0: meta?.[0]?.data?.all?.avg_basket || 0,
                    data_1: meta?.[1]?.data?.all?.avg_basket || 0,
                    data_2: meta?.[2]?.data?.all?.avg_basket || 0,
                    data_3: meta?.[3]?.data?.all?.avg_basket || 0,
                },
            ],
        };
    } else {
        console.warn("functionGlobal.jsx - Missing meta or metaCost data.");
    }
    console.log("functionGlobal.jsx - globalMeta result:", arr);
    return arr;
}

export function globalBookings(bookings) {
    console.log("functionGlobal.jsx - globalBookings called with bookings:", bookings);
    var arr = { data_0: [] };
    if (bookings !== null && bookings !== undefined && bookings.length !== 0 && bookings.length !== undefined) {
        bookings
            .sort((a, b) => new Date(b.dateReservation) - new Date(a.dateReservation))
            .forEach((item) => {
                arr["data_0"].push({
                    reference: item.reference,
                    date_arrivee: dayjs(new Date(item.checkInDate)).format("DD/MM/YYYY"),
                    date_depart: dayjs(new Date(item.checkOutDate)).format("DD/MM/YYYY"),
                    date_resa: dayjs(new Date(item.dateReservation)).format("DD/MM/YYYY"),
                    montant_resa: item.totalAmount,
                });
            });
    }
    console.log("functionGlobal.jsx - globalBookings result:", arr);
    return arr;
}

export function globalAll(data, isMMIP = false) {
    console.log("functionGlobal.jsx - globalAll called with data:", data, "isMMIP:", isMMIP);
    if (isMMIP) {
        const totalReservationsAmount = (data.meta?.totalAmount || 0) + (data.ads?.[0]?.data?.allConversionsValue || 0);
        const totalMetaAmount = data.meta?.totalMeta || 0;
        const totalAdsAmount = data.ads?.[0]?.data?.allConversionsValue || 0;
        return [
            {
                label: "Montant total des réservations",
                attribut: "€",
                data_0: totalReservationsAmount,
                data_1: 0,
                data_2: 0,
                data_3: 0,
            },
            {
                label: "Réservations Organiques",
                attribut: "€",
                data_0: totalReservationsAmount - totalAdsAmount - totalMetaAmount,
                data_1: 0,
                data_2: 0,
                data_3: 0,
            },
            {
                label: "Réservations Google Ads",
                attribut: "€",
                data_0: totalAdsAmount,
                data_1: 0,
                data_2: 0,
                data_3: 0,
            },
            {
                label: "Réservations Métas",
                attribut: "€",
                data_0: totalMetaAmount,
                data_1: 0,
                data_2: 0,
                data_3: 0,
            },
        ];
    } else {
        const hasMeta = data.meta && 
            Array.isArray(data.meta) && 
            data.meta.length >= 4 &&
            data.meta[0]?.data?.all_no_filter !== undefined;

        const totalReservationPeriods = [
            (hasMeta && data.meta[0]?.data?.all_no_filter !== null ? data.meta[0].data.all_no_filter.total_amount_booking : 0) || 
            (data.ads && data.ads[0]?.data?.allConversionsValue !== null ? data.ads[0].data.allConversionsValue : 0),
            
            (hasMeta && data.meta[1]?.data?.all_no_filter !== null ? data.meta[1].data.all_no_filter.total_amount_booking : 0) ||
            (data.ads && data.ads[1]?.data?.allConversionsValue !== null ? data.ads[1].data.allConversionsValue : 0),
            
            (hasMeta && data.meta[2]?.data?.all_no_filter !== null ? data.meta[2].data.all_no_filter.total_amount_booking : 0) ||
            (data.ads && data.ads[2]?.data?.allConversionsValue !== null ? data.ads[2].data.allConversionsValue : 0),
            
            (hasMeta && data.meta[3]?.data?.all_no_filter !== null ? data.meta[3].data.all_no_filter.total_amount_booking : 0) ||
            (data.ads && data.ads[3]?.data?.allConversionsValue !== null ? data.ads[3].data.allConversionsValue : 0)
        ];
        
        const adsValues = [
            data.ads && data.ads[0]?.data?.allConversionsValue !== null ? data.ads[0].data.allConversionsValue : 0,
            data.ads && data.ads[1]?.data?.allConversionsValue !== null ? data.ads[1].data.allConversionsValue : 0,
            data.ads && data.ads[2]?.data?.allConversionsValue !== null ? data.ads[2].data.allConversionsValue : 0,
            data.ads && data.ads[3]?.data?.allConversionsValue !== null ? data.ads[3].data.allConversionsValue : 0
        ];
        
        const metaValues = [
            hasMeta && data.meta[0]?.data?.all !== null ? data.meta[0].data.all.total_amount_booking : 0,
            hasMeta && data.meta[1]?.data?.all !== null ? data.meta[1].data.all.total_amount_booking : 0,
            hasMeta && data.meta[2]?.data?.all !== null ? data.meta[2].data.all.total_amount_booking : 0,
            hasMeta && data.meta[3]?.data?.all !== null ? data.meta[3].data.all.total_amount_booking : 0
        ];
        
        const organicValues = [
            totalReservationPeriods[0] === adsValues[0] ? 0 : totalReservationPeriods[0] - adsValues[0],
            totalReservationPeriods[1] === adsValues[1] ? 0 : totalReservationPeriods[1] - adsValues[1],
            totalReservationPeriods[2] === adsValues[2] ? 0 : totalReservationPeriods[2] - adsValues[2],
            totalReservationPeriods[3] === adsValues[3] ? 0 : totalReservationPeriods[3] - adsValues[3]
        ];
        
        const result = [
            {
                label: "Montant total des réservations",
                attribut: "€",
                data_0: totalReservationPeriods[0],
                data_1: totalReservationPeriods[1],
                data_2: totalReservationPeriods[2],
                data_3: totalReservationPeriods[3],
            },
            {
                label: "Réservations Organiques",
                attribut: "€",
                data_0: organicValues[0],
                data_1: organicValues[1],
                data_2: organicValues[2],
                data_3: organicValues[3],
            },
            {
                label: "Réservations Google Ads",
                attribut: "€",
                data_0: adsValues[0],
                data_1: adsValues[1],
                data_2: adsValues[2],
                data_3: adsValues[3],
            }
        ];
        
        if (hasMeta) {
            result.push({
                label: "Réservations Métas",
                attribut: "€",
                data_0: metaValues[0],
                data_1: metaValues[1],
                data_2: metaValues[2],
                data_3: metaValues[3],
            });
        }
        
        return result;
    }
}

export function macroAnalytics(data) {
    console.log("functionGlobal.jsx - macroAnalytics called with data:", data);
    return {
        label: "Utilisateurs",
        decimals: 0,
        data_0: data[1]["totals"] ? data[1]["totals"][0] : 0,
        data_1: data[3]["totals"] ? data[3]["totals"][0] : 0,
        data_2: data[5]["totals"] ? data[5]["totals"][0] : 0,
        data_3: data[7]["totals"] ? data[7]["totals"][0] : 0,
        tooltip: "Utilisateurs qui ont initié au moins une session dans la plage de dates sélectionnée.",
        isNotRound: true,
    };
}

export function macroAds(ads) {
    console.log("functionGlobal.jsx - macroAds called with ads:", ads);
    return {
        data: [
            {
                label: "Montant des réservations",
                decimals: 0,
                attribut: "€",
                data_0: ads[0].data.allConversionsValue,
                data_1: ads[1].data.allConversionsValue,
                data_2: ads[2].data.allConversionsValue,
                data_3: ads[3].data.allConversionsValue,
                tooltip: "Les montants des réservations sont la somme des réservations obtenues sur la période avec ads.",
            },
            {
                label: "ROI",
                decimals: 2,
                data_0: ads[0].data.roi,
                data_1: ads[1].data.roi,
                data_2: ads[2].data.roi,
                data_3: ads[3].data.roi,
            },
            {
                label: "Appels",
                decimals: 0,
                data_0: ads[0].data.phoneCalls,
                data_1: ads[1].data.phoneCalls,
                data_2: ads[2].data.phoneCalls,
                data_3: ads[3].data.phoneCalls,
                isNotRound: true,
                tooltip:
                    "Les annonces Appel sont conçues pour encourager les utilisateurs à contacter votre entreprise par téléphone. Elles ne peuvent être diffusées que sur les appareils qui permettent de passer des appels. Lorsqu'un client potentiel clique sur votre annonce, l'annonce déclenche un appel vers votre établissement depuis son appareil.",
            },
        ],
    };
}

export function macroMeta(meta, metaCost) {
    console.log("functionGlobal.jsx - macroMeta called with meta:", meta, "metaCost:", metaCost);
    return {
        data: [
            {
                label: "Montant des réservations",
                attribut: "€",
                decimals: 0,
                data_0: meta[0].data.all !== null ? meta[0].data.all.total_amount_booking : 0,
                data_1: meta[1].data.all !== null ? meta[1].data.all.total_amount_booking : 0,
                data_2: meta[2].data.all !== null ? meta[2].data.all.total_amount_booking : 0,
                data_3: meta[3].data.all !== null ? meta[3].data.all.total_amount_booking : 0,
                tooltip: "Les montants des réservations sont la somme des réservations obtenues sur la période avec meta.",
            },
            {
                label: "ROI",
                decimals: 2,
                data_0:
                    !metaCost["errors"] !== false && meta[0].data.all !== null && metaCost.datesRange[0].cost !== null
                        ? meta[0].data.all.total_amount_booking / metaCost.datesRange[0].cost
                        : 0,
                data_1:
                    !metaCost["errors"] !== false && meta[1].data.all !== null && metaCost.datesRange[1].cost !== null
                        ? meta[1].data.all.total_amount_booking / metaCost.datesRange[1].cost
                        : 0,
                data_2:
                    !metaCost["errors"] !== false && meta[2].data.all !== null && metaCost.datesRange[2].cost !== null
                        ? meta[2].data.all.total_amount_booking / metaCost.datesRange[2].cost
                        : 0,
                data_3:
                    !metaCost["errors"] !== false && meta[2].data.all !== null && metaCost.datesRange[2].cost !== null
                        ? meta[2].data.all.total_amount_booking / metaCost.datesRange[2].cost
                        : 0,
            },
        ],
    };
}
