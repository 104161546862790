import React, { useState, useEffect } from "react";
import CustomBigCard from "../custom/CustomBigCard";
import Reservations from "./Reservations";
import { get_meta_bookings } from "../../api";
import dayjs from "dayjs";
import { globalBookings } from "../admin/functionGlobal";
import { useSelector } from "react-redux";
import { selectEndDate, selectStartDate } from "../../reducers/filterSlice";

const Meta = ({ meta, period, property }) => {
    const [reservations, setReservations] = useState([]);
    const startDate = useSelector(selectStartDate);
    const endDate = useSelector(selectEndDate);
    const [data, setData] = useState(meta);

    console.log("Meta.jsx - initial meta data:", data);

    useEffect(() => {
        console.log("Meta.jsx - useEffect triggered with property id:", property?.id, "startDate:", startDate, "endDate:", endDate);
        if (!property?.id) {
            console.error("Meta.jsx - Missing property id. API call aborted.");
            return;
        }
        get_meta_bookings(property.id, dayjs(startDate).format("YYYY-MM-DD"), dayjs(endDate).format("YYYY-MM-DD"))
            .then((response) => {
                console.log("Meta.jsx - API response for meta bookings:", response);
                const processedReservations = globalBookings(response);
                console.log("Meta.jsx - Processed reservations:", processedReservations);
                setReservations(processedReservations.data_0 || []);
            })
            .catch((error) => {
                console.error("Meta.jsx - API call error:", error);
            });
    }, [property?.id, startDate, endDate]);

    console.log("Meta.jsx - rendering with data:", data);

    const hasValidData = data !== undefined && data !== null && data.data && data.data.some(item => 
        item && (item.data_0 > 0 || item.data_1 > 0 || item.data_2 > 0 || item.data_3 > 0)
    );

    if (!hasValidData) {
        return (
            <div className="analytics-card meta-empty-state">
                <svg width="64" height="64" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM13 17H11V11H13V17ZM13 9H11V7H13V9Z" fill="#3B61E1"/>
                </svg>
                <div className="meta-empty-header">
                    <h3>Aucune réservation Meta détectée</h3>
                </div>
                <div className="meta-empty-content">
                    <p>
                        Aucune réservation provenant des Métas n'a été enregistrée pour cette période. Si vous pensez que c'est une erreur, contactez notre équipe de support.
                    </p>
                </div>
                <a href="mailto:support@altelis.com" className="meta-support-button">
                    Contacter le support
                </a>
            </div>
        );
    }

    return (
        <div className="ads-container">
            <div className="ads-row">
                <CustomBigCard
                    data_first={data.data[0]}
                    data_second={data.data[1]}
                    data_third={data.data[5]}
                    period={period}
                    tooltip={data.data[0]?.tooltip || false}
                />
                <CustomBigCard
                    data_first={data.data[4]}
                    data_second={data.data[2]}
                    data_third={data.data[3]}
                    period={period}
                    tooltip={data.data[0]?.tooltip || false}
                    isInvert
                />
            </div>
            <Reservations data={reservations} period={period} />
        </div>
    );
};

export default Meta;
