import React, { useMemo, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import { selectPeriod } from "../../../../reducers/filterSlice";
import Analytics from "../../Analytics";
import Ads from "../../Ads";
import MetaMMIP from "../../MetaMMIP";
import MetaStandard from "../../Meta";
import AmountAll from "../../AmountAll";
import "./TabReservations.scss";

const TabReservations = ({ api, isLoaded }) => {
    const period = useSelector(selectPeriod);
    const { propertyId } = useParams();
    const [fadeIn, setFadeIn] = useState(false);
    const [prevApi, setPrevApi] = useState(null);
    const [displayApi, setDisplayApi] = useState(null);

    const affichageCard = (data) => {
        return Array.isArray(data) && data.some((item) => item.data_0 !== 0 || item.data_1 !== 0 || item.data_2 !== 0 || item.data_3 !== 0);
    };

    useEffect(() => {
        if (isLoaded && api) {
            setDisplayApi(api);
            setTimeout(() => {
                setFadeIn(true);
            }, 100);
        } else if (!isLoaded) {
            setPrevApi(displayApi);
            setFadeIn(false);
        }
    }, [isLoaded, api, displayApi]);

    useEffect(() => {
        if (api) {
            setPrevApi(api);
        }
    }, [api]);

    const content = useMemo(() => {
        if (!isLoaded || !api || !displayApi) {
            return (
                <div className="loader-container">
                    <CircularProgress size={30} />
                </div>
            );
        }

        if (!api) {
            return <div className="error-message">Erreur lors de la récupération des données</div>;
        }

        const currentApi = displayApi || api;

        return (
            <div className={`main-router-content ${fadeIn ? 'fade-in' : 'fade-out'}`}>
                <div className="view-container">
                    {affichageCard(currentApi.all) && (
                        <div className="transactions-container parts">
                            <div className="header-title parts">
                                <h2>Réservations directes</h2>
                            </div>
                            <AmountAll data={currentApi.all} period={period} />
                        </div>
                    )}
                    <div className="transactions-container analytics">
                        <div className="header-title analytics">
                            <h2>Google Analytics</h2>
                        </div>
                        <Analytics data={currentApi.analytics} period={period} property={{ id: propertyId, ...currentApi.property }} />
                    </div>
                    <div className="transactions-container adwords">
                        <div className="header-title ads">
                            <h2>Google Ads</h2>
                        </div>
                        <Ads data={currentApi.ads} period={period} />
                    </div>
                    {(parseInt(propertyId) === 92 || (currentApi.meta && currentApi.meta.data)) && (
                        <div className="transactions-container metas">
                            <div className="header-title meta">
                                <h2>Métas</h2>
                            </div>
                            {parseInt(propertyId) === 92 ? (
                                <MetaMMIP period={period} apiData={currentApi} />
                            ) : (
                                <MetaStandard meta={currentApi.meta} period={period} property={{ id: propertyId, ...currentApi.property }} />
                            )}
                        </div>
                    )}
                </div>
            </div>
        );
    }, [api, displayApi, isLoaded, period, propertyId, fadeIn]);

    return content;
};

export default TabReservations;
