import React, { useMemo } from "react";
import CustomList from "../custom/CustomList";
import classnames from "classnames";

const Reservations = ({ data }) => {
    console.log("Reservations.jsx - rendering with data:", data);
    const columns = useMemo(
        () => [
            {
                Header: "Référence",
                accessor: "reference",
                icon: "role",
            },
            {
                Header: "Date d'arrivée",
                accessor: "date_arrivee",
                icon: "calendar",
                Cell: ({ cell: { value } }) => value,
            },
            {
                Header: "Date de départ",
                accessor: "date_depart",
                icon: "calendar",
                Cell: ({ cell: { value } }) => value,
            },
            {
                Header: "Date de la réservation",
                accessor: "date_resa",
                icon: "calendar",
                Cell: ({ cell: { value } }) => value,
            },
            {
                Header: "Montant de la réservation",
                accessor: "montant_resa",
                class: "border-item",
                attribut: "€",
                icon: "euro",
                Cell: ({ cell: { value } }) => <span>{value.toFixed(2)}</span>,
            },
        ],
        []
    );

    const containerClasses = classnames("transactions-container input-orders-container", {
        sample: data?.sample,
    });

    return (
        <div className={containerClasses}>
            {data.length > 0 ? (
                <CustomList
                    entity={data}
                    columns={columns}
                    isFetchable={false}
                    isSelectable={false}
                    isPaginable={true}
                    pageSizeOptions={[5, 10]}
                    isSmall={true}
                />
            ) : (
                <div className="empty-reservations-container">
                    <svg width="64" height="64" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M19 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3ZM17 13H13V17H11V13H7V11H11V7H13V11H17V13Z" fill="#3B61E1"/>
                    </svg>
                    <p>Aucune réservation disponible</p>
                    <span>Essayez d'ajuster la période sélectionnée pour voir d'autres réservations ou vérifiez la configuration de votre compte.</span>
                </div>
            )}
        </div>
    );
};

export default React.memo(Reservations);
